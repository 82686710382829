<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <el-date-picker
                v-model="search.date_range"
                type="daterange"
                size="small"
                :picker-options="date_picker_options"
                @change="dateCheck"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date">
              </el-date-picker> &nbsp;
              <!-- <el-select v-model="dateFilterType" size="small">
                <el-option v-for="(item, i) in dateFilterTypeOptions" :key="i" :value="item.value" :label="item.text" />
              </el-select> &nbsp; -->
              <el-button :loading="loading.table" @click="page = 1; getList();" size="small" class="btn-primary" icon="el-icon-search">Search</el-button>
              <el-button v-if="feature_enabled" :loading="loading.table" @click="page = 1; downloadListBatch();" size="small" class="btn-default" icon="el-icon-download">Download</el-button>
              <!-- <el-button :loading="loading.table" @click="downloadReport();" size="small" class="btn-primary" icon="el-icon-position">Send to Email</el-button> -->
            </div>
            <hr/>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Batch Name</th>
                    <th>Send Time</th>
                    <th>Channel</th>
                    <th>Template Name</th>
                    <th>Total Recipient</th>
                    <!-- <th>Success Count</th>
                    <th>Failed Count</th>
                    <th>Progress</th> -->
                    <!-- <th>Amount</th> -->
                    <!-- <th>#</th> -->
                  </tr>
                </thead>
                <tbody v-if="batchList.count > 0" v-loading="loading.table">
                  <tr v-for="(item) in batchList.rows" :key="item.id" class="cursor-pointer">
                    <td @click="showDetailBatch(item)">{{ item.created_at_str }}</td>
                    <td @click="showDetailBatch(item)">{{ item.batch_name }}</td>
                    <td @click="showDetailBatch(item)">{{ item.send_time == 'now' ? item.created_at_str : item.scheduled_at_str }}</td>
                    <td @click="showDetailBatch(item)"><i :class="[item.channel_icon, item.channel_color]"/> {{item.channel_name_str}}</td>
                    <td @click="showDetailBatch(item)">{{ item.template_name }}</td>
                    <td @click="showDetailBatch(item)">{{ item.total_recipients }}</td>
                    <!-- <td @click="showDetailBatch(item)">{{ item.success_count }}</td>
                    <td @click="showDetailBatch(item)">{{ item.failed_count }}</td>
                    <td @click="showDetailBatch(item)">{{ item.progress_str }}</td> -->
                    <!-- <td @click="showDetailBatch(item)">{{ item.total_amount_str }}</td> -->
                    <!-- <td>
                      <el-button @click="confirmDownload(item.id)" size="small" class="btn-primary" icon="el-icon-download">Download</el-button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="batchList.count == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="batchList.count > per_page" v-model="currentPage" :total-rows="batchList.count" :per-page="per_page"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isEqual } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '../../../api/reports';
import customFeaturesApi from '../../../api/customFeatures';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'BroadcastReports',
  metaInfo: {
    title: 'Broadcast Reports',
  },
  data() {
    return {
      search: {
        date_range: [
          this.$route.query?.start_date || moment().subtract(7, 'days'),
          this.$route.query?.end_date || moment(),
        ],
      },
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
        shortcuts: [
          {
            text: 'This week',
            onClick(picker) {
              const start = moment().startOf('week').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'This month',
            onClick(picker) {
              const start = moment().startOf('month').format();
              const end = moment().format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last week',
            onClick(picker) {
              const start = moment().subtract(1, 'week').startOf('week').format();
              const end = moment().subtract(1, 'week').endOf('week').format();
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const start = moment().subtract(1, 'month').startOf('month').format();
              const end = moment().subtract(1, 'month').endOf('month').format();
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      loading: {
        table: false,
      },
      page: this.$route.query.page || 1,
      per_page: 20,
      batchList: {
        count: 0,
        rows: [],
      },
      feature_enabled: false,
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getList();
      },
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
    this.getCustomFeature();
  },
  methods: {
    async getCustomFeature() {
      const response = await customFeaturesApi.getCustomFeature(this.activeWorkspace._id).catch(() => {});
      if (response.error) {
        return;
      }
      this.feature_enabled = response.data.download_broadcast_report_periode;
    },
    dateCheck() {
      const [start_date, end_date] = this.search.date_range;
      const minTime = moment(start_date);
      const maxTime = moment(end_date);
      const diff = maxTime.diff(moment(minTime), 'd');
      if (diff > 30) {
        this.$message({
          title: this.$t('general.error.general'),
          type: 'warning',
          message: 'Reporting period that can be selected for 31 days',
          duration: 20 * 100,
          showClose: true,
        });
        this.search.date_range = [start_date, start_date];
      }
    },
    async getList() {
      this.loading.table = true;
      this.dateCheck();
      const options = {
        page: this.page.toString(),
        per_page: this.per_page.toString(),
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
      };
      const response = await reportApi.batchList({
        workspace_id: this.activeWorkspace._id,
        ...options,
      });
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      await popupErrorMessages(response);
      this.batchList = response.data;
      if (response.data.rows) {
        this.batchList.rows = response.data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          if (v.scheduled_at) {
            v.scheduled_at_str = moment(v.scheduled_at).format('DD MMM YYYY, HH:mm');
          }
          v.progress = (v.waiting_count / v.total_recipients) * 100;
          v.channel_icon = `mdi mdi-${v.channel_name}`;
          if (v.channel_name === 'whatsapp') {
            v.channel_name_str = 'WhatsApp';
            v.channel_color = 'text-success';
          }
          v.progress_str = `${v.complete_count} of ${v.total_recipients}`;
          v.total_amount_str = `Rp. ${v.total_amount ? v.total_amount.toLocaleString() : 0}`;
          return v;
        });
      }
      this.loading.table = false;
    },
    async downloadListBatch() {
      this.loading.table = true;
      this.dateCheck();
      const options = {
        page: this.page.toString(),
        per_page: this.per_page.toString(),
        start_date: moment(this.search.date_range[0]).format('YYYY-MM-DD'),
        end_date: moment(this.search.date_range[1]).format('YYYY-MM-DD'),
      };
      const response = await reportApi.downloadBatchList({
        workspace_id: this.activeWorkspace._id,
        ...options,
      });
      if (!isEqual(options, this.$route.query)) {
        this.$router.replace({ query: options });
      }
      await popupErrorMessages(response);
      this.$message({
        title: this.$t('general.success'),
        type: 'success',
        message: this.$t('general.waiting_download'),
        duration: 30 * 1000,
        showClose: true,
      });
      this.loading.table = false;
    },
    async showDetailBatch(item) {
      await this.$store.dispatch('report/setActiveBatch', item);
      this.$router.push({
        name: 'Broadcast Reports Detail',
        params: {
          id: item.id,
        },
      });
    },
    confirmDownload(batch_id) {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.loading.table = true;
            const response = await reportApi.batchDetailDownload({
              workspace_id: this.activeWorkspace._id,
              batch_id,
            }).catch(() => {});
            this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
  },
};
</script>
